<template>
  <div id="techenrollplan">techenrollplan</div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
export default {
  mixins: [part],
  name: "techenrollplan",
};
</script>

<style>
</style>